import Vue from 'vue'
import VueRouter from 'vue-router'
import QrLogin from '../views/QrLogin.vue'
import error from '../views/ErrorPage.vue'

import axios from "axios"
import store from "../store"
import request from "@/axios";

Vue.use(VueRouter)

// 用于路由注册

const routes = [
    {
        path: '/qrLogin/*', name: 'qrLogin', component: QrLogin, meta: {title: '扫码登陆'},
        // children: [
        //     {path: '/Home', name: 'Home', component: Home, meta: {title: '主页'}},
        // ]
    },
    {path: '/', name: 'qrLogin', component: QrLogin, meta: {title: '扫码登陆'}},
    {path: '*', name: 'error', component: error, meta: {title: '出错了，没找到此页面！！！'}}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router
