<template>
  <div class="error">
    <h4>没找到此页面</h4>
    <van-empty image="error" description="错误的页面">
      <van-button round type="danger" class="bottom-button" @click="back('back')">返回上一步</van-button>
      <van-button round type="danger" class="bottom-button" @click="back('sm')">返回扫码界面</van-button>
    </van-empty>
  </div>
</template>
<script>

import router from "@/router"

export default {
  name: "errorPage",
  components: {},
  data() {
    return {}
  },
  created() {
  }, methods: {
    back(tag) {
      if (tag === 'sm') {
        router.push("/qrLogin/")
      } else {
        this.$router.go(-1);
      }
    }
  }
}
</script>

<style scoped>

.error {
  height: 100%;
  width: 100%;
  text-align: center;
}

.bottom-button {
  width: 160px;
  height: 40px;
}

</style>