import axios from "axios"
import router from "@/router"
import {Dialog} from "vant";

// 用于接口请求

// axios.defaults.baseURL = "http://localhost:8082"
//frp 内网穿透地址
let protocol = window.location.protocol; //协议
let host = window.location.host; //主机
let reg = /^localhost+/;
if(reg.test(host)) {
    //若本地项目调试使用
    axios.defaults.baseURL = "https://sm.hezuvip.com/mallapi"
} else {
    //动态请求地址             协议               主机
    axios.defaults.baseURL = protocol + "//" + host + "/mallapi"
}

// axios.defaults.baseURL = "https://api.frp.wangwei.run"

const request = axios.create({
    timeout: 50000,
    headers: {
        'Content-Type': "application/json; charset=utf-8"
    }
})

request.interceptors.request.use(config => {
    // config.headers['Authorization'] = localStorage.getItem("token")
    return config
})

request.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.data) {
        error.message = error.response.data.msg
    }
    console.log(error)
    Dialog.alert({
        title: '出错了',
        message: error.message
    })
    return Promise.reject(error)
})
export default request