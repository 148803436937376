import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import Vant from 'vant';
import 'vant/lib/index.css';
import axios from "./axios"
import wx from "weixin-jsapi"

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.wx = wx

import { Toast } from 'vant';

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.use(Toast);
Vue.use(Vant);
// require("./mock")

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default router
