<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  watch: {
    $route(to, from) {
      console.log("to")
      console.log(to)
    }
  }
}
</script>
<style>
html, body, #app{
  height: 100%;
  margin: 0;
  padding: 0;
  overflow:scroll;
  /*禁用x轴的滚动条*/
  overflow-x:hidden;
}

a {
  text-decoration: none;
}
</style>
